import {
    login,
    visitorLogin,
    phoneLogin,
    logout,
    register,
    phoneRegister,
    weChatlogin,
    balance,
    info,
    verify_code,
    email_update,
    email_code,
    email_move,
    phone_code,
    phone_update,
    phone_remove_code,
    phone_remove,
    card_auth,
    pwd_code,
    pwd_reset,
    invited_list,
    pwdReset,
    generateKey
} from '@/api/user'
import { getToken, setToken, removeToken, removeGuestToken, removeUserId, setUserId } from '@/utils/auth'
import { resetRouter } from '@/router'

const state = {
    token: getToken(),
    userid: '',
    name: '',
    avatar: '',
    introduction: '',
    balance: 0,
    roles: [],
    username: '',
    avatarPicUrl: '',
    email: '',
    phone: '',
    inviteCode: '',
    inviteUrl: '',
    inviteUrlQrImageUrl: '',
    invited_list: [],
    statusText: '',
    code: '',
    secretKey: '',
    branchId: '',
    levelNum: '',
    levelName: '',
    isCardNo: '',
    collectNum: '',
    browseNum: '',

}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatarPicUrl = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_USER_ID: (state, userid) => {
        state.userid = userid
    },
    SET_BALANCE: (state, balance) => {
        state.balance = balance
    },
    SET_INFO: (state, data) => {
        Object.keys(state).forEach(key => { state[key] = data[key] })
    },
    SET_INVITED_LISS: (state, data) => {
        state.invited_list = data
    },
    SET_EMAIL: (state, data) => {
        state.email = data
    },
    SET_PHONE: (state, data) => {
        state.phone = data
    },
    SET_SECRET_KEY: (state, data) => {
        state.secretKey = data
    },
    SET_IS_FZ: (state, data) => {
        state.branchId = data
    },
    SET_LEVEL_NUM: (state, data) => {
        state.levelNum = data
    },
    SET_LEVEL_NAME: (state, data) => {
        state.levelName = data
    },
    SET_CARDNO: (state, data) => {
        state.isCardNo = data
    },

    SET_COLLECT_NUM: (state, data) => {
        state.collectNum = data
    },

    SET_BROWSE_NUM: (state, data) => {
        state.browseNum = data
    },
}

const actions = {
    //账号登录
    login({ commit }, userInfo) {
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            login({ username: username.trim(), password: password }).then(response => {
                const { token, user_id } = response.result
                commit('SET_TOKEN', token)
                commit('SET_USER_ID', user_id)
                setToken(token)
                setUserId(user_id)
                console.log(getToken())
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    //游客登录/注册
    visitorLogin({ commit }) {
        return new Promise((resolve, reject) => {
            visitorLogin({ inviterCode: localStorage.getItem("inviterCode") }).then(response => {
                const { token, user_id } = response.result
                commit('SET_TOKEN', token)
                commit('SET_USER_ID', user_id)
                setToken(token)
                setUserId(user_id)
                console.log(getToken())
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    //手机号登录/注册
    phoneLogin({ commit }, userInfo) {
        const { phoneNumber, smsVerificationCode } = userInfo
        return new Promise((resolve, reject) => {
            phoneLogin({ phoneNumber: phoneNumber.trim(), smsVerificationCode: smsVerificationCode }).then(response => {
                const { token, user_id } = response.result
                commit('SET_TOKEN', token)
                commit('SET_USER_ID', user_id)
                setToken(token)
                setUserId(user_id)
                console.log(getToken())
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    weChatlogin({ commit }, userInfo) {
        const { code } = userInfo
        return new Promise((resolve, reject) => {
            weChatlogin({ code: code }).then(response => {
                console.log(response)
                const { token, user_id } = response.result
                commit('SET_TOKEN', token)
                commit('SET_USER_ID', user_id)
                setToken(token)
                setUserId(user_id)
                console.log(getToken())
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    register({ state }, userInfo) {
        return new Promise((resolve, reject) => {
            register({...userInfo }).then(() => {
                console.log(state)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },


    phoneRegister({ state }, userInfo) {
        return new Promise((resolve, reject) => {
            phoneRegister({...userInfo }).then(() => {
                console.log(state)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout({ user_id: state.userid, token: state.token }).then(() => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                commit('SET_USER_ID', '')
                removeToken()
                removeGuestToken()
                removeUserId()
                resetRouter()

                resolve()
            }).catch(error => {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                commit('SET_USER_ID', '')
                removeToken()
                removeGuestToken()
                removeUserId()
                reject(error)
            })
        })
    },
    balance({ commit }) {
        return new Promise((resolve, reject) => {
            balance().then((res) => {
                commit('SET_BALANCE', res.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    info({ commit }) {
        return new Promise((resolve, reject) => {
            info().then((res) => {
                commit('SET_INFO', res.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    verify_code({}, data) {
        return new Promise((resolve, reject) => {
            verify_code({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    email_update({}, data) {
        return new Promise((resolve, reject) => {
            email_update({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // eslint-disable-next-line no-empty-pattern
    email_code({}, data) {
        return new Promise((resolve, reject) => {
            email_code({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    email_move({}, data) {
        return new Promise((resolve, reject) => {
            email_move({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    generateKey({ commit }, data) {
        return new Promise((resolve, reject) => {
            generateKey({...data }).then((res) => {
                console.log(res.result)
                commit('SET_SECRET_KEY', res.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    phone_code({}, data) {
        return new Promise((resolve, reject) => {
            phone_code({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    phone_update({}, data) {
        return new Promise((resolve, reject) => {
            phone_update({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    phone_remove_code({}, data) {
        return new Promise((resolve, reject) => {
            phone_remove_code({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    phone_remove({}, data) {
        return new Promise((resolve, reject) => {
            phone_remove({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    pwd_code({}, data) {
        return new Promise((resolve, reject) => {
            pwd_code({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    pwd_reset({}, data) {
        return new Promise((resolve, reject) => {
            pwd_reset({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    pwdReset({}, data) {
        return new Promise((resolve, reject) => {
            pwdReset({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    card_auth({}, data) {
        return new Promise((resolve, reject) => {
            card_auth({...data }).then((res) => {
                console.log(res)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    invited_list({ commit }, data) {
        return new Promise((resolve, reject) => {
            invited_list({...data }).then((res) => {
                commit('SET_INVITED_LISS', res.result)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}